import { ROUTING } from '../../routing';

class Reports {
  static instance = new Reports();

  ReportName = {
    REGISTRATIONS: 'registrations',
    LATEST_PARTNERS: 'latestPartners',
    COMMISSIONS: 'commissions',
    CPA_COMMISSIONS: 'cpa-commissions',
    TRADES: 'trades',
    PAYMENTS: 'payments',
    BALANCES: 'balances',
    ADJUSTMENTS: 'adjustments',
    FEES: 'fees',
    LOGIN_ATTEMPTS: 'loginAttempts',
  };

  REPORTS_CONFIG = {
    [this.ReportName.LOGIN_ATTEMPTS]: {
      title: 'Login Attempts Report',
      path: ROUTING.LOGIN_ATTEMPTS_REPORTS,
    },
    [this.ReportName.REGISTRATIONS]: {
      title: 'Registration Report',
      path: ROUTING.REGISTRATION_REPORTS,
    },
    [this.ReportName.LATEST_PARTNERS]: {
      title: 'Latest Partners Report',
      path: ROUTING.LATEST_PARTNERS_REPORTS,
    },
    [this.ReportName.COMMISSIONS]: {
      title: 'Commissions Report',
      path: ROUTING.COMMISSION_REPORTS,
    },
    [this.ReportName.CPA_COMMISSIONS]: {
      title: 'CPA Commissions Report',
      path: ROUTING.CPA_COMMISSION_REPORTS,
    },
    [this.ReportName.TRADES]: {
      title: 'Trades Report',
      path: ROUTING.TRADES_REPORTS,
    },
    [this.ReportName.PAYMENTS]: {
      title: 'Payments Report',
      path: ROUTING.PAYMENTS_REPORTS,
    },
    [this.ReportName.BALANCES]: {
      title: 'Balances Report',
      path: ROUTING.BALANCES_REPORTS,
    },
    [this.ReportName.ADJUSTMENTS]: {
      title: 'Adjustments Report',
      path: ROUTING.ADJUSTMENTS_REPORT,
    },
    [this.ReportName.FEES]: {
      title: 'Fees Report',
      path: ROUTING.FEES_REPORT,
    },
  };

  getFmReports() {
    return this._build(
      this.ReportName.REGISTRATIONS,
      this.ReportName.LATEST_PARTNERS,
      this.ReportName.COMMISSIONS,
      this.ReportName.CPA_COMMISSIONS,
      this.ReportName.TRADES,
      this.ReportName.PAYMENTS,
      this.ReportName.BALANCES,
      this.ReportName.ADJUSTMENTS,
      this.ReportName.LOGIN_ATTEMPTS,
    );
  }

  getGpReports() {
    return this.getFmReports();
  }

  getDay1Reports() {
    return this._build(
      this.ReportName.REGISTRATIONS,
      this.ReportName.LATEST_PARTNERS,
      this.ReportName.COMMISSIONS,
      this.ReportName.FEES,
      this.ReportName.PAYMENTS,
      this.ReportName.BALANCES,
      this.ReportName.ADJUSTMENTS,
      this.ReportName.LOGIN_ATTEMPTS,
    );
  }

  _build(...reportNames) {
    return reportNames.map((reportName) => this.REPORTS_CONFIG[reportName]);
  }
}

export const ReportsService = Reports.instance;

import { ROUTING } from 'config';

const HEADER_TITLES = [
  {
    path: ROUTING.DASHBOARD,
    title: 'Dashboard',
  },
  {
    path: ROUTING.CLIENTS,
    title: 'Clients',
  },
  {
    path: ROUTING.COMMISSIONS,
    title: 'My Commission',
  },
  {
    path: ROUTING.SETTING,
    title: 'Profile Settings',
  },
  {
    path: ROUTING.PARTNERS,
    title: 'Partners',
    excludes: ['partner-profile'],
  },
  {
    path: ROUTING.LATEST_PARTNERS_REPORTS,
    title: 'Reports',
    excludes: ['partner-profile'],
  },
  {
    path: ROUTING.REGISTRATION_REPORTS,
    title: 'Reports',
    excludes: ['partner-profile'],
  },
  {
    path: ROUTING.TRADES_REPORTS,
    title: 'Reports',
  },
  {
    path: ROUTING.FEES_REPORT,
    title: 'Reports',
  },
  {
    path: ROUTING.COMMISSION_REPORTS,
    title: 'Reports',
    excludes: ['partner-profile'],
  },
  {
    path: ROUTING.CPA_COMMISSION_REPORTS,
    title: 'Reports',
    excludes: ['partner-profile'],
  },

  {
    path: ROUTING.PAYMENTS_REPORTS,
    title: 'Reports',
  },
  {
    path: ROUTING.BALANCES_REPORTS,
    title: 'Reports',
  },
  {
    path: ROUTING.LOGIN_ATTEMPTS_REPORTS,
    title: 'Reports',
  },
  {
    path: ROUTING.ADJUSTMENTS_REPORT,
    title: 'Reports',
  },
  {
    path: ROUTING.STAFF_MANAGEMENT,
    title: 'Staff Management',
  },
  {
    path: ROUTING.WITHDRAWALS,
    title: 'Withdrawals',
  },
];

export const getSectionTitle = (section, sectionChild) => {
  const currentPage = HEADER_TITLES.find(
    ({ path, excludes = [] }) =>
      path.slice(1) === section &&
      (!excludes.length || excludes.every((child) => sectionChild !== child)),
  );

  return currentPage?.title || '';
};

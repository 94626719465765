import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import SearchModal from 'components/Modals/SearchModal';
import StyledButton from 'components/Common/Buttons/StyledButton';
import SearchInput from 'components/Common/Input/SearchInput';

import Actions from 'redux/actions';
import { ROUTING } from 'config';
import { useBrand } from 'modules/shared/brand/hooks/useBrand';
import useToggle from 'utils/hooks/useToggle';
import useDebounce from 'utils/hooks/useDebounce';
import { getClients } from 'redux/sagas/search/selectors';
import { getUserRole } from 'redux/sagas/auth/selectors';

import styles from './styles.module.less';

import { USER_ROLES } from 'utils/constants';

const UserSearch = ({ optionType, placeholder }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalOpen, onSearchToggle] = useToggle();
  const { clients, isLoading, total, offset } = useSelector(getClients);
  const userRole = useSelector(getUserRole);
  const { brand } = useBrand();

  useEffect(
    () => () => {
      dispatch(Actions.resetSearch());
    },
    [dispatch],
  );

  const onSearchChange = useDebounce((value) => {
    if (value) {
      dispatch(
        Actions.searchClients.request({
          search: value,
          brand,
        }),
      );
    }
  }, 800);

  const onClientSelected = useCallback(
    (id) => {
      const path =
        userRole === USER_ROLES.BROKER
          ? ROUTING.CLIENTS_PROFILE_PATH
          : ROUTING.PARTNERS_PARTNER_PROFILE_PATH;
      history.push(path + id);
    },
    [history, userRole],
  );

  const onPopupScroll = useCallback(
    ({ target }) => {
      if (total > offset) {
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
          dispatch(Actions.searchMoreClients.request());
        }
      }
    },
    [dispatch, offset, total],
  );

  return (
    <>
      <SearchInput
        optionType={optionType}
        onSelect={onClientSelected}
        onChange={onSearchChange}
        options={clients}
        placeholder={placeholder}
        className={styles.search}
        onPopupScroll={onPopupScroll}
        isLoading={isLoading}
      />
      <div className={styles.mobileSearch}>
        <StyledButton
          icon="headerSearch"
          type="icon"
          onClick={onSearchToggle(true)}
          className={styles.searchButton}
        />
        <SearchModal
          isOpen={isModalOpen}
          options={clients}
          optionType={optionType}
          onSelect={onClientSelected}
          onSearchChange={onSearchChange}
          onCancel={onSearchToggle(false)}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

UserSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  optionType: PropTypes.string.isRequired,
};

export default UserSearch;

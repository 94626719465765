export const RouteBuilder = {
  EXAMPLE: (id, category) => `/dashboard/${id}/qa/${category}`,
};

export const ROUTING = {
  LOGIN: '/auth',
  SIGN_UP: '/sign-up',
  VERIFY_EMAIL: '/verify-email',
  ONBOARDING: '/onboarding',
  PARTNERS: '/partners',
  DASHBOARD: '/dashboard',
  CLIENTS: '/clients',
  CLIENTS_ACTIVE: '/clients/:activeLink',
  SETUP_MFA: '/mfa-setup',
  // profile routes
  CLIENTS_PROFILE: '/clients-profile/:id',
  PARTNER_PROFILE: '/partner-profile/:id',
  PARTNERS_PARTNER_PROFILE: '/partners/partner-profile/:id',
  LATEST_PARTNERS_REPORTS_PARTNER_PROFILE:
    '/latest-partners-reports/partner-profile/:id',
  COMMISSION_REPORTS_PARTNER_PROFILE: '/commission-reports/partner-profile/:id',
  CPA_COMMISSION_REPORTS_PARTNER_PROFILE:
    '/cpa-commission-reports/partner-profile/:id',
  // profile path routes
  CLIENTS_PROFILE_PATH: '/clients-profile/',
  PARTNER_PROFILE_PATH: '/partner-profile/',
  PARTNERS_PARTNER_PROFILE_PATH: '/partners/partner-profile/',
  LATEST_PARTNERS_REPORTS_PARTNER_PROFILE_PATH:
    '/latest-partners-reports/partner-profile/',
  COMMISSION_REPORTS_PARTNER_PROFILE_PATH:
    '/commission-reports/partner-profile/',
  CPA_COMMISSION_REPORTS_PARTNER_PROFILE_PATH:
    '/cpa-commission-reports/partner-profile/',
  CLIENTS_PARTNERS: '/clients/partners',
  COMMISSIONS: '/commission',
  RESET: '/forgot',
  NEWPASSWORD: '/new-password',
  SETTING: '/settings',
  NOTIFICATIONS: '/notifications',
  CLIENT_PARTNERS: '/partners',
  CLIENT_DASHBOARD: '/dashboard',
  APPLICATIONS: '/applications',
  LATEST_PARTNERS_REPORTS: '/latest-partners-reports',
  REGISTRATION_REPORTS: '/registration-reports',
  TRADES_REPORTS: '/trades-reports',
  COMMISSION_REPORTS: '/commission-reports',
  CPA_COMMISSION_REPORTS: '/cpa-commission-reports',
  PAYMENTS_REPORTS: '/payment-reports',
  BALANCES_REPORTS: '/balance-reports',
  ADJUSTMENTS_REPORT: '/adjustments-report',
  FEES_REPORT: '/fees-report',
  LOGIN_ATTEMPTS_REPORTS: '/login-attempts-report',
  STAFF_MANAGEMENT: '/staff-management',
  WITHDRAWALS: '/withdrawals',
  NOT_FOUND_ERROR: '/404-error',
  EXAMPLE: RouteBuilder.EXAMPLE(':id', ':categoryId'),
};

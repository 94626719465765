import React from 'react';
import PropTypes from 'prop-types';

import ModalContainer from 'components/Common/ModalContainer';
import SearchInput from 'components/Common/Input/SearchInput';

import styles from './styles.module.less';

const SearchModal = ({
  isOpen,
  onSearchChange,
  options,
  onCancel,
  onSelect,
  isLoading,
  optionType,
}) => (
  <ModalContainer
    className={styles.modal}
    isOpen={isOpen}
    onCancel={onCancel}
    closable={false}
    centered={false}
  >
    <SearchInput
      optionType={optionType}
      isLoading={isLoading}
      placeholder="Search for a client here"
      onChange={onSearchChange}
      onSelect={onSelect}
      options={options}
    />
  </ModalContainer>
);

SearchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  optionType: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default SearchModal;
